var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{staticClass:"modal-retur",attrs:{"visible":_vm.showModal,"footer":false,"width":_vm.showTable ? 900 : 450},on:{"cancel":_vm.resetField}},[_c('h5',[_vm._v(" Tambah Produk "+_vm._s(_vm.pages === 'redeem' ? 'Redeem' : _vm.pages === 'shopee_id' ? 'Shopee' : _vm.pages === 'tokopedia_id' ? 'Tokopedia' : 'Distributorship')+" ")]),_c('hr'),_c('div',{staticClass:"mt-4"},[_vm._v(" Anda dapat membuat produk "+_vm._s(_vm.pages === 'redeem' ? 'Redeem' : _vm.pages === 'shopee_id' ? 'Shopee' : _vm.pages === 'tokopedia_id' ? 'Tokopedia' : 'Distributorship')+" dari produk master yang sudah ada atau membuat produk baru. ")]),_c('div',{staticClass:"mt-3"},[_c('a-button',{staticClass:"inbound__button mx-2 ml-auto",on:{"click":function($event){$event.preventDefault();return _vm.addProduct.apply(null, arguments)}}},[_vm._v(" Buat Produk Baru ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleShow}},[_vm._v(" Gunakan Produk Yang Ada ")])],1),_vm._v(" "),(_vm.showTable)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex align-items-center mx-auto"},[_c('div',{staticClass:"search-bar"},[_c('input',{attrs:{"type":"text","placeholder":_vm.$t('product.searchBarPlaceholder')},domProps:{"value":_vm.keyword || ''},on:{"input":_vm.handleSearchBar}}),_c('icon-search',{staticClass:"icon-form"})],1)]),_c('div',{staticClass:"mt-3"},[_c('a-table',{attrs:{"data-source":_vm.dataProductMaster,"row-key":(item, index) => index,"columns":_vm.columns,"loading":_vm.loading,"pagination":false,"scroll":{ y: 500 }},scopedSlots:_vm._u([{key:"catalogs",fn:function(text, record){return [_c('div',[_vm._v(" "+_vm._s(record.catalogs?.length)+" ")])]}},{key:"category",fn:function(text, record){return [_c('div',[_vm._v(" "+_vm._s(record.category?.name)+" ")])]}},{key:"action",fn:function(text, record){return [_c('RouterLink',{staticClass:"d-flex align-items-center",attrs:{"to":{ 
                name: _vm.pages === 'redeem' ? 'redeem-channel.create-new-redeem-product-channel' : 
                  _vm.pages !== 'distributor' ? 'product.channel.' + _vm.pages : 'product.channel.distributor', 
                query: {
                  workspace_id: _vm.$route.query.workspace_id,
                  business_id: record.business_id,
                  id: record.id,
                  channel_code: _vm.$route.query.channel_code,
                  redirect_url: _vm.$route.fullPath,
                  master_product: true,
                } 
              }}},[_c('a-button',{attrs:{"type":"primary","ghost":""}},[_vm._v(" Pilih Produk ")])],1)]}}],null,false,2021848648)})],1),_c('div',{staticClass:"mt-3 text-right"},[_c('a-pagination',{attrs:{"page-size":_vm.params?.limit,"page-size-options":_vm.sizeOptions,"total":_vm.params?.total,"show-total":(total, range) => _vm.$t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total }),"disabled":_vm.loading,"show-size-changer":""},on:{"change":_vm.changePage,"showSizeChange":_vm.changeSize},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }