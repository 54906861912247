<template>
  <div>
    <a-modal
      :visible="showModal"
      :footer="false"
      :width="showTable ? 900 : 450"
      class="modal-retur"
      @cancel="resetField"
    >
      <h5>
        Tambah Produk {{ 
          pages === 'redeem' ? 'Redeem' : 
          pages === 'shopee_id' ? 'Shopee' : 
          pages === 'tokopedia_id' ? 'Tokopedia' : 
          'Distributorship' 
        }}
      </h5>
      <hr />

      <div class="mt-4">
        Anda dapat membuat produk {{ 
          pages === 'redeem' ? 'Redeem' : 
          pages === 'shopee_id' ? 'Shopee' : 
          pages === 'tokopedia_id' ? 'Tokopedia' : 
          'Distributorship' 
        }} dari produk master yang sudah ada atau membuat produk baru.
      </div>

      <div class="mt-3">
        <a-button class="inbound__button mx-2 ml-auto" @click.prevent="addProduct">
          Buat Produk Baru
        </a-button>
        <a-button type="primary" :loading="loading" @click="handleShow">
          Gunakan Produk Yang Ada
        </a-button>
      </div>

      <!-- DATA --> <!-- LIST -->
      <div v-if="showTable" class="mt-4">
        <div class="d-flex align-items-center mx-auto">
          <div class="search-bar">
            <input
              type="text"
              :placeholder="$t('product.searchBarPlaceholder')"
              :value="keyword || ''"
              @input="handleSearchBar"
            />
            <icon-search class="icon-form" />
          </div>
        </div>
        <div class="mt-3">
          <a-table
            :data-source="dataProductMaster"
            :row-key="(item, index) => index"
            :columns="columns"
            :loading="loading"
            :pagination="false"
            :scroll="{ y: 500 }"
          >
            <template slot="catalogs" slot-scope="text, record">
              <div>
                {{ record.catalogs?.length }}
              </div>
            </template>
            <template slot="category" slot-scope="text, record">
              <div>
                {{ record.category?.name }}
              </div>
            </template>
            <template slot="action" slot-scope="text, record">
              <RouterLink
                :to="{ 
                  name: pages === 'redeem' ? 'redeem-channel.create-new-redeem-product-channel' : 
                    pages !== 'distributor' ? 'product.channel.' + pages : 'product.channel.distributor', 
                  query: {
                    workspace_id: $route.query.workspace_id,
                    business_id: record.business_id,
                    id: record.id,
                    channel_code: $route.query.channel_code,
                    redirect_url: $route.fullPath,
                    master_product: true,
                  } 
                }"
                class="d-flex align-items-center"
              >
                <a-button type="primary" ghost>
                  Pilih Produk
                </a-button>
              </RouterLink>
            </template>
          </a-table>
        </div>
        <div class="mt-3 text-right">
          <a-pagination
            v-model="params.page"
            :page-size="params?.limit"
            :page-size-options="sizeOptions"
            :total="params?.total"
            :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
            :disabled="loading"
            show-size-changer
            @change="changePage"
            @showSizeChange="changeSize"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import IconSearch from '@/components/Icons/Search.vue'
import { i18n } from '@/i18n'
import debounce from 'lodash/debounce'

export default {
  name: 'ModalRetur',
  components: {
    IconSearch,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    pages: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
    dataProductMaster: {
      type: Array,
      default: () => [],
    },
    sizeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
      columns: [
        {
          title: i18n.t('order.productName'),
          dataIndex: 'title',
          key: 'name',
          width: 350,
          ellipsis: true,
        },
        {
          title: i18n.t('redeem.category'),
          dataIndex: 'category',
          key: 'category',
          class: 'text-center',
          scopedSlots: {
            customRender: 'category',
          },
          ellipsis: true,
        },
        {
          title: i18n.t('redeem.variant'),
          dataIndex: 'catalogs',
          key: 'catalogs',
          scopedSlots: {
            customRender: 'catalogs',
          },
          class: 'text-center',
          ellipsis: true,
        },
        {
          title: '',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      showTable: false,
      keyword: '',
    }
  },
  watch: {
    row: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.dataRetur = value
      },
    },
    showModal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          this.visibleReject = value
          this.visibleUpdate = value
          this.isReason = ''
        }
      },
    },
  },
   methods: {
    handleShow() {
      this.showTable = !this.showTable
    },
    addProduct() {
      const name = this.pages === 'redeem' ? 'redeem-channel.create-new-redeem-product-channel' : 
        this.pages !== 'distributor' ? 'product.channel.' + this.pages : 'product.channel.distributor'
        console.log({name})
      const { workspace_id, business_id, sales_channel_id, channel_code } = this.$route.query
      this.$router.push({
        name: name,
        query: {
          workspace_id,
          business_id,
          sales_channel_id,
          channel_code,
          redirect_url: this.$route.fullPath,
        },
      })
      // this.addProductModal.open = true
    },
    changePage(param) {
      this.$emit('onPageChangeMaster', param)
    },
    changeSize(current, size) {
      this.$emit('onShowSizeChangeMaster', size)
    },
    handleSearchBar: debounce(function(e) {
      const value = e.target.value
      this.keyword = value
      this.$emit('onSearchMaster', value)
    }, 500),
    resetField() {
      this.showTable = false
      this.keyword = ''
      this.$emit('handleModal', false)
    },
  },
}
</script>

<style lang="scss">
.modal-retur {
  .list {
  border-radius: 5px;
  }
  .header-list {
    font-weight: 900;
    background: #E7ECEF;
    border-radius: 5px ;
    padding: 10px;
  }
  .data-list {
    padding: 5px 0;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px ;
  }

  // ANT DESIGN
  // .ant-modal {
  //   width: 75% !important;
  // }
  .inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }
}
.inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }
.search-bar {
    position: relative;
    width: 534px;
    height: 44px;

    input[type='text'] {
      border: 1px solid #999;
      border-radius: 5px;
      width: 100%;
      outline: none;
      padding: 8px;
      box-sizing: border-box;
      transition: 0.3s;
    }

    input[type='text']:focus {
      border-color: rgb(5, 89, 203);
      box-shadow: 0 0 8px 0 rgb(5, 89, 203);
      color: #999;
    }

    input[type='text'] {
      padding-left: 40px;
    }

    input::placeholder {
      color: #999 !important;
      font-family: 'Poppins', sans-serif !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 21px !important;
    }
    .icon-form {
      position: absolute;
      left: 7px;
      top: 10px;
      color: #999;
      transition: 0.3s;
    }
  }
</style>